import { env } from '$lib/env';
import * as Sentry from '@sentry/sveltekit';
import { handleErrorWithSentry } from '@sentry/sveltekit';

// TODO: should this go in hooks.server.ts????
if (env === 'prod') {
    Sentry.init({
        dsn: 'https://f8ffe1b64e496817150cc451751021ab@o4507603910131712.ingest.us.sentry.io/4507810425536512',
        tracesSampleRate: 1.0,

        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,

        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,

        // If you don't want to use Session Replay, just remove the line below:
        // integrations: [replayIntegration()]
    });
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
