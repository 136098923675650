import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~2],
		"/admin": [~18],
		"/admin/companies": [~19],
		"/admin/companies/[companyId]": [~20],
		"/admin/dev": [~21],
		"/admin/dev/personas": [~22],
		"/admin/dev/test-csv-rag": [~23],
		"/admin/integrations": [~24],
		"/admin/integrations/[integrationId]": [~25],
		"/admin/models": [~26],
		"/admin/models/[modelId]": [~27],
		"/admin/roadmaps": [~28],
		"/admin/templates": [~29],
		"/admin/templates/[templateId]": [~30],
		"/admin/users": [~31],
		"/admin/users/[userId]": [~32],
		"/admin/vokers": [~33],
		"/admin/vokers/[vokerId]": [~34],
		"/apps/content-marketing": [~35],
		"/apps/contract-reviewer": [~36],
		"/apps/contract-reviewer/config": [~37],
		"/(testing-things)/a": [12],
		"/(testing-things)/dev": [13],
		"/(testing-things)/dev/home": [14],
		"/(testing-things)/dev/project": [15],
		"/editor": [38],
		"/json": [39],
		"/(legal)/privacy": [10],
		"/propai-advisor": [40],
		"/rag-chat": [~41],
		"/rag-chat/[chatId]": [~42],
		"/rag-chat/[chatId]/settings": [~43],
		"/(testing-things)/scalable": [16],
		"/settings": [~44],
		"/settings/tabs/integrations/slack-app-confirmation": [~45],
		"/(auth)/sign-in": [3],
		"/(auth)/sign-in/google-confirmation": [4],
		"/(auth)/sign-in/propai-advisor": [5],
		"/(auth)/sign-out": [6],
		"/(auth)/sign-up": [7],
		"/(auth)/sign-up/google-confirmation": [8],
		"/(auth)/sign-up/propai-advisor": [9],
		"/(legal)/terms": [11],
		"/(testing-things)/testStream": [17],
		"/vokers": [~46],
		"/vokers/[vokerId]": [~47]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';